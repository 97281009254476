import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { COLOR } from "../../components/header";
import SEO from "../../components/seo";
import TrailTitle from "../../components/TrailTitle";
import { romanNumberToDec } from "../../pages/quality";

const delay = keyframes`
  0% {
    opacity: 0;
    height : 0;
  }

  100% {
    opacity: 0;
    height : initial;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const DelayFade = styled(Col)`
  animation: ${delay} ${(props) => props.order * 0.1}s,
    ${fade} 0.1 ${(props) => props.order * 0.1}s;
`;

const BgRow = styled(Row)`
  background-image: url("${(props) => props.background}");
  background-repeat: no-repeat;
  background-position: top right;
`;

const QualityByYearPage = ({ location, data, pageContext, ...props }) => {
  return (
    <>
      <SEO title='Indikator Mutu Layanan' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <BgRow className='pt-5' background={data.bg.childImageSharp.fixed.src}>
        <Col
          xs={"12"}
          md={"4"}
          className='d-flex flex-column align-items-center align-content-center justify-content-center'>
          <TrailTitle open className='pb-2 mb-5'>
            <div>Indikator Mutu Layanan</div>
            <div>Tahun {pageContext.year}</div>
          </TrailTitle>
        </Col>
        <Col xs={"12"} sm={{ span: "9", offset: 1 }} className='mb-2'>
          <h4>
            Laporan indikator mutu layanan dibuat secara triwulan, klik lihat
            untuk detail laporan.
          </h4>
        </Col>
        <Col xs={"12"} sm={{ span: "8", offset: 2 }}>
          <div>
            <Row className='p-1'>
              {data.quality.edges
                .sort(
                  (a, b) =>
                    romanNumberToDec[b.node.trimester.value] -
                    romanNumberToDec[a.node.trimester.value]
                )
                .map(({ node: { trimester, cockpitId } }, idx) => (
                  <DelayFade sm={"3"} key={cockpitId} order={idx}>
                    <Card>
                      <Card.Img
                        variant='top'
                        src={data.logo.childImageSharp.fixed.src}
                      />
                      <Card.Body>
                        <Card.Title>Triwulan {trimester.value}</Card.Title>
                        <AniLink
                          paintDrip
                          hex={COLOR}
                          to={`/quality/${pageContext.year}/${trimester.value}`}
                          className='btn btn-primary btn-block'>
                          Lihat Laporan
                        </AniLink>
                      </Card.Body>
                    </Card>
                  </DelayFade>
                ))}
            </Row>
          </div>
        </Col>
      </BgRow>
    </>
  );
};

export const query = graphql`
  query($year: Date!) {
    logo: file(name: { eq: "logo-2023" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    quality: allCockpitLaporanMutu(
      filter: {
        display: { value: { eq: true } }
        year: { value: { eq: $year } }
      }
    ) {
      edges {
        node {
          cockpitId
          cockpitCreated
          year {
            value
          }
          trimester {
            value
          }
        }
      }
    }
  }
`;

export default QualityByYearPage;
